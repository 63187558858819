import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop, Watch} from 'vue-property-decorator'

@Component
export default class DialogMixin extends Vue {
    @Prop({default: false})
    visible!: boolean

    @Watch('visible', {deep: false})
    visibleChanged(newVal, oldVal) {
        if (newVal) {
            document.body.classList.add('w-100');
            document.body.style.overflow = 'unset';
            document.body.style.top = `-${window.scrollY}px`;
            this.$nextTick(() => {
                document.body.style.position = 'fixed';
            })
        }
        else {
            document.body.classList.remove('w-100');
            const scrollY = document.body.style.top;
            this.$nextTick(() => {
                // document.body.style.removeProperty('overflow')
                document.body.style.top = '';
                document.body.style.position = '';
                this.$nextTick(() => {
                    window.scroll(0, parseInt(scrollY || '0') * -1)
                    // document.body.style.removeProperty('overflow')
                    // window.scroll(0, parseInt(scrollY || '0') * -1)
                })
            })

        }
    }

    set dialogVisible(v) {
        this.$emit('update:visible', v)
    }

    get dialogVisible() {
        return this.visible
    }
}
