export default {
    "0": "CHINESE",
    "1": "ENGLISH",
    "2": "MATHEMATICS",
    "3": "LS",
    "4": "CHIN LIT.",
    "5": "ENG LIT.",
    "6": "CHIN HISTORY",
    "7": "ECON",
    "8": "E&RS",
    "9": "GEOG",
    "10": "HISTORY",
    "11": "THS",
    "12": "BIO",
    "13": "CHEM",
    "14": "PHY",
    "15": "IS",
    "16": "BAFS",
    "17": "DAT",
    "18": "HMSC",
    "19": "ICT",
    "20": "PE",
    "21": "T&L",
    "22": "MUSIC",
    "23": "VA",
    "24": "M1",
    "25": "M2",
    "99": ""
};
