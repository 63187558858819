

















































































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {Verification, VerificationStatus, VerificationType} from '~/components/data-class/data-class'
import {MemberPermission} from '~/utils/permissions'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import MemberVerificationDialog from '~/components/member/member-verification-dialog.vue'
import VerificationContent from '~/components/member/verification-content.vue'
import {checkPendingVerification} from '~/utils/noti'

@Component({
    components: {
        MemberVerificationDialog,
        VerificationContent
    }
})

export default class VerificationList extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = MemberPermission.Verifications

    // data
    verifications: Verification[] = []

    VerificationType = VerificationType
    // filter
    typeArray = [
        {
            title: 'All Types',
            value: ''
        },
        {
            title: 'DSE',
            value: VerificationType.DSE
        },
        {
            title: 'School',
            value: VerificationType.SCHOOL
        }
    ]

    VerificationStatus = VerificationStatus
    statusStr = {
        [VerificationStatus.VERIFIED]: 'Verified',
        [VerificationStatus.NOT_VERIFIED]: 'Not Verified',
        [VerificationStatus.REJECTED]: 'Rejected',
    }
    statusArray = [
        {
            title: this.statusStr[VerificationStatus.VERIFIED],
            value: VerificationStatus.VERIFIED
        },
        {
            title: this.statusStr[VerificationStatus.NOT_VERIFIED],
            value: VerificationStatus.NOT_VERIFIED
        },
        {
            title: this.statusStr[VerificationStatus.REJECTED],
            value: VerificationStatus.REJECTED
        }
    ]

    // page
    step: number = 20

    queryDef = [
        {localVar: 'type'},
        {localVar: 'status', type: QueryType.Number},
        {localVar: 'keyword'},
        {localVar: 'image', type: QueryType.Boolean},
    ]
    // search
    type: string = ''
    status: VerificationStatus | '' = ''
    keyword: string = ''
    image: boolean = true

    preview_image: string = ''
    loading = true

    dialogVisible = false
    verifying: Verification = new Verification()

    filterChange() {
        this.getVerification()
    }

    async toPage(pageNumber: number) {
        this.currentPage = pageNumber
        this.getVerification()
    }

    async created() {
        this.parseQuery()
        await this.setQuery(false)
        this.getVerification()

        await checkPendingVerification(true)
    }

    getVerification(pageNumber: number = -1) {
        if (pageNumber !== -1) this.currentPage = pageNumber
        this.loading = true
        const param: { [key: string]: string } = {
            skip: (this.currentPage - 1) * this.step + '',
            limit: this.step + '',
            // count: "true" .
        }
        if (this.type !== '') param.type = this.type
        if (this.status !== '') param.status = this.status + ''
        if (this.keyword !== '') param.keyword = this.keyword
        if (this.image) param.image = 'true'

        createRequest('/member/verifications', 'get', param).send()
            .then((res) => {
                this.verifications = res.data['verifications']
                this.totalCount = res.data['count']
                this.loading = false
            })
        this.setQuery()
    }

    startVerify(verification: Verification) {
        this.verifying = verification
        this.dialogVisible = true
    }

    async verify(id: string, accept: boolean) {
        const param: any = {accept}
        if (!accept) {
            param.failed_reason = this.verifying.failed_reason
            param.failed_card_type = this.verifying.failed_card_type
        }

        const l = this.$loading({})
        await createRequest('/member/verification/' + id + '/verify', 'post', {}, param).send()
            .then(()=>{
                this.$message.success('Updated')
            })
            .finally(() => {
                l.close()
            })
        let findVerification: Verification = new Verification()
        const obj = this.verifications.find((v: Verification) => {
            return v._id == id
        })
        if (obj != undefined) {
            findVerification = obj
            findVerification.status = accept ? 1 : -1
        }

        await checkPendingVerification(true)
    }
}

