import {createRequest} from '~/utils/network-request'
import {MenuSubitem} from '~/components/menu/menu-data'

let pendingVerification = false
let item: MenuSubitem

export async function getPendingVerification(force = false) {
    if (pendingVerification && !force)
        return true

    const res = await createRequest('/verification/pending').send()
    pendingVerification = res.data.exists
    return pendingVerification
}

export async function checkPendingVerification(force = false) {
    if(!item)
        return

    await getPendingVerification(force)
        .then(exists => {
            item.alert = exists
        })
}

export async function registerVerificationMenuItem(i: MenuSubitem) {
    item = i

    await checkPendingVerification()
    setInterval(async () => {
        await checkPendingVerification()
    }, 60000)
}
