

















































import {Component, Emit, Prop, Watch} from 'vue-property-decorator'
import {FailedReason, Verification, VerificationType} from '~/components/data-class/data-class'
import VerificationContent from './verification-content.vue'
import {mixins} from 'vue-class-component'
import ImageDialogMixin from '~/mixins/image-dialog-mixin'

@Component({
    components: {VerificationContent}
})
export default class MemberVerificationDialog extends mixins(ImageDialogMixin) {
    @Prop({
        default: () => {
            return new Verification()
        }
    })
    verification: Verification

    rejectVisible = false

    cardType: string = ''
    failType: string = ''

    failedReason: FailedReason[] = []

    created() {
    }

    @Emit('accept')
    async accept() {
        this.dialogVisible = false
    }

    async reject() {
        if (this.verification.type === VerificationType.SCHOOL) {
            this.failedReason = this.verification.failed_reason || []

            if (!this.verification.failed_card_type)
                this.verification.failed_card_type = '學生證'

            this.rejectVisible = true
        } else {
            this.$emit('reject')
            this.dialogVisible = false
        }
    }

    confirmReject() {
        this.verification.failed_reason = this.failedReason
        this.$emit('reject')
        this.rejectVisible = false
        this.dialogVisible = false
    }

    @Watch('verification._id')
    reset() {
        this.failType = ''
        this.cardType = ''
    }

    get imageUrl() {
        return this.verification.file_url
    }
}
