






















import {Component, Prop, Vue} from 'vue-property-decorator'
import {Verification, VerificationType, SchoolName} from '~/components/data-class/data-class'
import _subjects from '~/data/subjects'
import _dseMarks from '~/data/dse-marks'
import {schoolLookup} from "@afterschool.dev/as-data-admin";

@Component({
    components: {}
})
export default class VerificationContent extends Vue {
    @Prop({
        default: () => {
            return new Verification()
        }
    })
    verification: Verification

    VerificationType = VerificationType
    subjects = _subjects
    dseMarks = _dseMarks

    school(schoolNo: number): string {
        const obj: object = schoolLookup[schoolNo + '']
        if (obj != undefined) {
            return schoolLookup[schoolNo + ''].zh
        } else {
            return 'N/A'
        }
    }
}
