import Component from 'vue-class-component'
import {Ref, Watch} from 'vue-property-decorator'
import DialogMixin from '~/mixins/dialog-mixin'
import {initImageViewer} from '~/utils/misc'

@Component
export default class ImageDialogMixin extends DialogMixin {
    @Ref()
    imgArea: HTMLElement

    get imageUrl() {
        return ''
    }

    @Watch('visible')
    vChanged(newVal, oldVal) {
        this.$nextTick(()=>{
            initImageViewer(this.imgArea, this.imageUrl)
        })
    }
}
